import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { Observable } from 'rxjs';
import { AppSettingService } from '../services/appsetting.service';

// Allow access to route only if city is Turku and setting is enabled.

@Injectable({
  providedIn: 'root',
})
export class CanActivateGuard implements CanActivate {
  constructor(private router: Router, private appSettingsService: AppSettingService) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const municipalityRouteSlug = route.params.slug;
    const hoodRouteSlug = route.params.city;

    if (municipalityRouteSlug !== 'turku' && municipalityRouteSlug) {
      const url = state.url.split('/events')[0];
      return this.router.parseUrl(url);
    }

    if (hoodRouteSlug !== 'turku' && hoodRouteSlug) {
      const url = state.url.split('/events')[0];
      return this.router.parseUrl(url);
    }

    return this.appSettingsService.eventsEnabled().then((enabled) => {
      if (!enabled) {
        if (municipalityRouteSlug) {
          const url = state.url.split('/events')[0];
          return this.router.parseUrl(url);
        }
        if (hoodRouteSlug) {
          const url = state.url.split('/events')[0];
          return this.router.parseUrl(url);
        }
      }

      return true;
    });
  }
}

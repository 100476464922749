import { FormGroup } from '@angular/forms';

// Validates the month of residency
export function validateDate(monthInput: string, yearInput: string) {
  return (formGroup: FormGroup) => {
    const monthControl = formGroup.controls[monthInput];
    const yearControl = formGroup.controls[yearInput];

    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const currentMonth = currentDate.getMonth() + 1;

    let yearInputAsNumber = parseInt(yearControl.value, 10);

    // return if another validator has already found an error on the controls
    if (monthControl.errors && !monthControl.errors.invalidMonth) {
      return;
    }

    if (yearInputAsNumber === currentYear) {
      if (monthControl.value > currentMonth) {
        monthControl.setErrors({ invalidMonth: true });
      } else {
        monthControl.setErrors(null);
      }
    } else {
      monthControl.setErrors(null);
    }
  };
}

import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { IReview } from '../../models/IReview';
import { endpoints } from '../../configs/sources';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
};

@Injectable({
  providedIn: 'root',
})
export class ReviewService {
  readonly API_URL = endpoints.reviews;

  constructor(private http: HttpClient) {}

  // Get all reviews without ratings
  getReviews(): Observable<IReview[]> {
    return this.http.get<IReview[]>(this.API_URL, httpOptions);
  }

  // Get a review by ID including ratings
  getReviewById(id: number): Observable<any> {
    return this.http.get(this.API_URL + '/' + id, httpOptions);
  }

  // Get all reviews from hood ID including ratings
  getReviewsByHood(hood: number): Observable<any> {
    return this.http.get<any>(this.API_URL + '/hood/' + hood, httpOptions);
  }

  // Post a review
  postReview(reviewData: any, hoodId: number, userId: number, dev: boolean): Observable<any> {
    let features = reviewData.features;

    let featuresMap = Object.values(features);

    const review = {
      hoodId,
      userId,
      title: reviewData.title,
      text: reviewData.comment,
      ratings: featuresMap,
    };

    return this.http.post<any>(this.API_URL + '?dev=' + dev, review, httpOptions);
  }

  // Update a review of ID
  updateReview(userReview, reviewData: any): Observable<IReview> {
    let userRatings = userReview.ratings;
    let reviewId = userRatings[0].reviewId;
    let features = reviewData.features;

    let featuresMap = Object.values(features);

    const review = {
      title: reviewData.title,
      text: reviewData.comment,
      ratings: featuresMap,
    };

    return this.http.put<IReview>(this.API_URL + '/' + reviewId, review, httpOptions);
  }

  // Delete a review of ID
  deleteReview(id: number, dev: boolean): Observable<IReview> {
    return this.http.delete<IReview>(this.API_URL + '/' + id + '?dev=' + dev, httpOptions);
  }

  getCategories(): Observable<any> {
    return this.http.get<any>(this.API_URL + '/category', httpOptions);
  }

  getAverageRatingsByHood(hoodId: number): Observable<any> {
    return this.http.get<any>(this.API_URL + '/rating/' + hoodId, httpOptions);
  }
}
